import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" >
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Site is under Construction.       
        <h5>Fantisera Studio, creators of The Last Realm.</h5>
        </p>
      </header>
    </div>
  );
}

export default App;
